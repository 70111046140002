export function module_common() {
	
	(function ($) {
    var ua = navigator.userAgent;

    $("#panel-btn").on("click", function () {
      responsiveToggle();
    });
    $("#header .responsive a").on("click", function () {
      responsiveToggle();
    });
    $("#header a.page-top").on("click", function () {
      if ($("#panel-btn-icon").is(".close")) {
        responsiveToggle();
      }
      //var speed = 300;
      //$('html, body').animate({scrollTop: 0}, speed, 'swing');
      //return false;
    });

    function responsiveToggle() {
      $("#panel-btn-icon").toggleClass("close");

      if (ua.match(/android/i)) {
        $("#header .responsive").fadeToggle(200);
      } else {
        $("#header .responsive").slideToggle(200);
      }
    }

    // sp menu open
    $(document).ready(function () {
      spMenuOpen();

      $(window).on("resize orientationchange", function () {
        spMenuOpen();
      });

      // sp menu open
      function spMenuOpen() {
        var winH = $(window).height();
        var headerH = $(".spHeader").height();
        if (!$("#header .navbar").is(":visible")) {
          $("#header .responsive").css({ height: winH, top: headerH });
        }
      }
    });

    // header search focus color
    $(".siteSearch input")
      .focus(function () {
        $(".osd .siteSearch i, .usd .siteSearch i").css("color", "#01579b");
      })
      .blur(function () {
        $(".osd .siteSearch i, .usd .siteSearch i").css("color", "#FFF");
      });

    $(window).on("load", function () {
      var winH = $(window).height();

      var url = $(location).attr("href");
      if (!$("#header .navbar").is(":visible")) {
        //console.log('sp');
        var headH = 72;
      } else {
        //console.log('pc');
        var headH = 150;
      }
      if (url.indexOf("?id=") != -1) {
        var id = url.split("?id=");
        var $target = $("#" + id[id.length - 1]);
        if ($target.length) {
          var pos = $target.offset().top - headH;
          $("html, body").animate({ scrollTop: pos }, 300, "swing");
        }
      }
    });

    $(function () {
      commonContents();

      var timer = false;
      var count = 0;
      var winWidth = $(window).width();
      var winHeight = $(window).height();
      var winWidth_resized;
      var winHeight_resized;

      //--------------------------------------------------------
      // Resize Event
      //--------------------------------------------------------
      $(window).on("resize", function () {
        // リサイズ後の放置時間が指定ミリ秒以下なら何もしない(リサイズ中に何度も処理が行われるのを防ぐ)
        if (timer !== false) {
          clearTimeout(timer);
        }

        // 放置時間が指定ミリ秒以上なので処理を実行
        timer = setTimeout(function () {
          // リサイズ後のウインドウの横幅・高さを取得
          winWidth_resized = $(window).width();
          winHeight_resized = $(window).height();

          // リサイズ前のウインドウ幅・髙さとリサイズ後のウインドウ幅・髙さが異なる場合
          if (winWidth != winWidth_resized || winHeight != winHeight_resized) {
            commonContents();

            // 次回以降使えるようにリサイズ後の幅・髙さをウインドウ幅に設定する
            winWidth = $(window).width();
            winHeight = $(window).height();
          }
        }, 200);
      });

      //リサイズ用関数
      function commonContents() {
        //timerをクリア、countをリセット
        clearTimeout(timer);
        count = 0;

        //ヘッダーロゴの src取得
        var logoSrc = $(".h-logo-pc").children("img").attr("src");

        function logoBlue() {
          if (!$("body#serviceLp").length) {
            var src = logoSrc.replace("logo-pc-white.svg", "logo-pc.svg");
            $(".h-logo-pc").children("img").attr("src", src);
          }
        }

        function logoWhite() {
          if (!$("body#serviceLp").length) {
            var src = logoSrc.replace("logo-pc.svg", "logo-pc-white.svg");
            $(".h-logo-pc").children("img").attr("src", src);
          }
        }

        if (window.innerWidth >= 1044) {
          logoWhite();
          if ($("#header").hasClass("headerfix")) {
            logoBlue();
          }
        } else {
          logoBlue();
          $(window).on("load scroll", function () {
            logoBlue();
          });
        }

        if (window.innerWidth >= 788) {
          $(".header-menu-btn > a:not(.active)").click(function (event) {
            event.preventDefault();
          });

          //メニューをマウスオーバーでサブメニュー表示
          $(".header-menu-btn").hover(
            function () {
              $(this).children(".header-menu-sub").stop().fadeIn(300);
              $(this).find(".material-icons-outlined").addClass("rotate");
            },
            function () {
              $(this).children(".header-menu-sub").stop().fadeOut(300);
              $(this).find(".material-icons-outlined").removeClass("rotate");
            }
          );
        }

        //Pagetop & Header Class
        var topBtn = $(".pagetop");
        topBtn.hide();

        $(window).on("scroll", function () {
          if ($(this).scrollTop() > 160) {
            topBtn.fadeIn();
          } else {
            topBtn.fadeOut();
          }

          if ($(this).scrollTop() > 260) {
            $("#header, .spHeader").addClass("headerfix");
            logoBlue();
          } else {
            $("#header, .spHeader").removeClass("headerfix");
            if (window.innerWidth >= 1044) {
              logoWhite();
            } else {
              logoBlue();
            }
          }
        });

        //スクロールしてトップ
        topBtn.on("click", function () {
          $("body,html").animate(
            {
              scrollTop: 0,
            },
            800,
            "swing"
          );
          return false;
        });
      }
    });

    //--------------------------------------------------------
    // slick Setteing
    //--------------------------------------------------------
    $(window).on("load", function () {
      //slick setting
      var slick = $(".slider").slick({
        appendArrows: $(".arrows"),
        centerMode: true,
        variableWidth: true,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 3,
        slidesToScroll: 1,
        pauseOnHover: true,
        /*responsive: [
				{
					breakpoint: 787,
					settings: {
						centerMode: false,
						variableWidth: false,
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]*/
      });

      $(".slick-next").on("click", function () {
        slick.slick("slickNext");
      });
      $(".slick-prev").on("click", function () {
        slick.slick("slickPrev");
      });
    });

    //--------------------------------------------------------
    // Lazy Load
    //--------------------------------------------------------
    if (!$("header").hasClass("en")) {
      $("img[src]:not(.noLazy)").each(function () {
        $(this)
          .attr("data-src", $(this).attr("src"))
          .removeAttr("src")
          .addClass("lazyload");
      });
    }

    //$('img.lazyload').lazyload();
  })(jQuery);
}