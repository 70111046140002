export function module_index() {
	(function ($) {
    topContents();

    var timer = false;
    var count = 0;
    var winWidth = $(window).width();
    var winHeight = $(window).height();
    var winWidth_resized;
    var winHeight_resized;

    //--------------------------------------------------------
    // Resize Event
    //--------------------------------------------------------
    $(window).on("resize", function () {
      // リサイズ後の放置時間が指定ミリ秒以下なら何もしない(リサイズ中に何度も処理が行われるのを防ぐ)
      if (timer !== false) {
        clearTimeout(timer);
      }

      // 放置時間が指定ミリ秒以上なので処理を実行
      timer = setTimeout(function () {
        // リサイズ後のウインドウの横幅・高さを取得
        winWidth_resized = $(window).width();
        winHeight_resized = $(window).height();

        // リサイズ前のウインドウ幅・髙さとリサイズ後のウインドウ幅・髙さが異なる場合
        if (winWidth != winWidth_resized || winHeight != winHeight_resized) {
          topContents();

          // 次回以降使えるようにリサイズ後の幅・髙さをウインドウ幅に設定する
          winWidth = $(window).width();
          winHeight = $(window).height();
        }
      }, 200);
    });

    //リサイズ用関数
    function topContents() {
      //timerをクリア、countをリセット
      clearTimeout(timer);
      count = 0;

      //画面高さを取得
      var wHeight = $(window).height();
      //ヘッダー高さを取得
      var headerHeight = $("#header").height();

      if (window.innerWidth >= 1024) {
        $(window).on("load", function () {
          //メインビジュアルのスクロールアニメーション
          $(".mainVisual__hlBox").each(function () {
            var $winH = $(window).height(),
              $mainText = $(this),
              position = $mainText.offset().top,
              opacity = 1,
              scroll,
              visualScale,
              visualPosition;

            $(window).on("scroll", function () {
              scroll = $(window).scrollTop();
              opacity = 1 - (scroll - position) / ($winH * 0.8);
              visualScale = 1 + (scroll - position) / ($winH * 2);
              visualPosition = 1 + (scroll - position) / ($winH * 0.002);

              if (opacity > 0.99) {
                opacity = 1;
              }

              if (visualScale < 1) {
                visualScale = 1;
              }

              if (scroll > position - $winH) {
                $mainText.css({ opacity: opacity, top: -visualPosition });
                $(".mainVisual").css({
                  transform: "scale(" + visualScale + ")",
                  top: -visualPosition,
                });
              }
            });
          });
        });
      } else {
        $(".mainVisual, .mainVisualInner, .mainVisual__hlBox").removeAttr(
          "style"
        );
      }

      $(window).on("load scroll", function () {
        //スクロールが画面サイズを超えたら、メインビジュアルを非表示
        if ($(this).scrollTop() > wHeight + 100) {
          $(".mainVisual, .mainVisual__hlBox").hide();
          $(".mainVisual__hl, .mainVisual__read, .mainVisualLPBtn").removeClass(
            "animation"
          );
        } else {
          $(".mainVisual, .mainVisual__hlBox").show();
        }
      });
    }
  })(jQuery);
}