export function module_contact() {
	(function ($) {
    var mwwpClass = ".mw_wp_form ";
    var validateId = [
      "#company_name",
      "#company_department",
      "#company_position",
      "#last_name",
      "#first_name",
      "#phone",
      "#contact_category",
      "input[name='contact_knowing']",
      "#contact_content",
      "input[name^='agree']",
    ];

    //文字列と配列を結合
    var result = validateId.map((elm) => {
      return mwwpClass + elm;
    });

    //結合した要素を対象にクラスを追加
    $(result).each(function () {
      $(this).addClass("validate[required]");
    });
    $(".mw_wp_form form input[name='email']").addClass(
      "validate[required],custom[email]"
    );
    $(".mw_wp_form form input[name^='contact_trigger']").addClass(
      "validate[minCheckbox[1]]"
    );

    //jQuery Validation Engine Setting
    $("#form_saleseforce, .mw_wp_form form").validationEngine("attach", {
      promptPosition: "topLeft", //エラー文の表示位置
      showArrowOnRadioAndCheckbox: true, //エラー箇所の図示
      focusFirstField: true, //エラー時に一番文頭の入力フィールドにフォーカスさせるかどうか
      scroll: false,
    });

    $(".btn__send, .sendBtn").click(function (event) {
      if (
        !$("#form_saleseforce").validationEngine("validate", {
          promptPosition: "topLeft",
          focusFirstField: true,
          scroll: false,
        })
      ) {
        return false;
      } else {
        $(".selectItem").addClass("selectItem__confirm");
        $(".nameBox").addClass("nameBox__confirm");
        $(".radioItem").addClass("radioItem__confirm");
        $(".checkboxItem").addClass("checkboxItem__confirm");
        //入力されたメールアドレスを取得して、name="email" の value に設定
        //var emailVal = $('#email').val();
        //$("#email_send").val(emailVal);
      }

      $(".radioItem input").each(function () {
        if ($(this).css("display") == "none") {
          $(this).next().hide();
        }
      });

      return true;
    });

    $("btn__back, .backBtn").click(function () {
      $(".selectItem").removeClass("selectItem__confirm");
      $(".nameBox").removeClass("nameBox__confirm");
      $(".radioItem").removeClass("radioItem__confirm");
      $(".checkboxItem").removeClass("checkboxItem__confirm");
    });
  })(jQuery);
}