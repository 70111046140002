export function module_validationEngineJa() {
	;/*****************************************************************
	 * Japanese language file for jquery.validationEngine.js (ver2.0)
	 *
	 * Transrator: tomotomo ( Tomoyuki SUGITA )
	 * http://tomotomoSnippet.blogspot.com/
	 * Licenced under the MIT Licence
	 *******************************************************************/
	(function ($) {
		$.fn.validationEngineLanguage = function () {};
		$.validationEngineLanguage = {
			newLang: function () {
				$.validationEngineLanguage.allRules = {
					"required": { // Add your regex rules here, you can take telephone as an example
						"regex": "none",
						"alertText": "* 必須項目です",
						"alertTextCheckboxMultiple": "* 選択してください",
						"alertTextCheckboxe": "* チェックボックスをチェックしてください"
					},
					"requiredInFunction": {
						"func": function (field, rules, i, options) {
							return (field.val() == "test") ? true : false;
						},
						"alertText": "* Field must equal test"
					},
					"minSize": {
						"regex": "none",
						"alertText": "* ",
						"alertText2": "文字以上にしてください"
					},
					"groupRequired": {
						"regex": "none",
						"alertText": "* You must fill one of the following fields"
					},
					"maxSize": {
						"regex": "none",
						"alertText": "* ",
						"alertText2": "文字以下にしてください"
					},
					"min": {
						"regex": "none",
						"alertText": "* ",
						"alertText2": " 以上の数値にしてください"
					},
					"max": {
						"regex": "none",
						"alertText": "* ",
						"alertText2": " 以下の数値にしてください"
					},
					"past": {
						"regex": "none",
						"alertText": "* ",
						"alertText2": " より過去の日付にしてください"
					},
					"future": {
						"regex": "none",
						"alertText": "* ",
						"alertText2": " より最近の日付にしてください"
					},
					"maxCheckbox": {
						"regex": "none",
						"alertText": "* チェックしすぎです"
					},
					"minCheckbox": {
						"regex": "none",
						"alertText": "* ",
						"alertText2": "つ以上チェックしてください"
					},
					"equals": {
						"regex": "none",
						"alertText": "* 入力された値が一致しません"
					},
					"creditCard": {
						"regex": "none",
						"alertText": "* 無効なクレジットカード番号"
					},
					"phone": {
						// credit: jquery.h5validate.js / orefalo
						"regex": /^([\+][0-9]{1,3}([ \.\-])?)?([\(][0-9]{1,6}[\)])?([0-9 \.\-]{1,32})(([A-Za-z \:]{1,11})?[0-9]{1,4}?)$/,
						"alertText": "* 電話番号が正しくありません"
					},
					"email": {
						// Shamelessly lifted from Scott Gonzalez via the Bassistance Validation plugin http://projects.scottsplayground.com/email_address_validation/
						"regex": /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i,
						"alertText": "* メールアドレスが正しくありません"
					},
					"integer": {
						"regex": /^[\-\+]?\d+$/,
						"alertText": "* 整数を半角で入力してください"
					},
					"number": {
						// Number, including positive, negative, and floating decimal. credit: orefalo
						"regex": /^[\-\+]?((([0-9]{1,3})([,][0-9]{3})*)|([0-9]+))?([\.]([0-9]+))?$/,
						"alertText": "* 数値を半角で入力してください"
					},
					"date": {
						"regex": /^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/,
						"alertText": "* 日付は半角で YYYY-MM-DD の形式で入力してください"
					},
					"ipv4": {
						"regex": /^((([01]?[0-9]{1,2})|(2[0-4][0-9])|(25[0-5]))[.]){3}(([0-1]?[0-9]{1,2})|(2[0-4][0-9])|(25[0-5]))$/,
						"alertText": "* IPアドレスが正しくありません"
					},
					"ip": {
						"regex": /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/,
						"alertText": "* IPアドレスが正しくありません"
					},
					"url": {
						"regex": /^(https?|ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i,
						"alertText": "* URLが正しくありません"
					},
					"onlyNumberSp": {
						"regex": /^[0-9\ ]+$/,
						"alertText": "* 半角数字で入力してください"
					},
					"onlyLetterSp": {
						"regex": /^[a-zA-Z\ \']+$/,
						"alertText": "* 半角アルファベットで入力してください"
					},
					"onlyLetterNumber": {
						"regex": /^[0-9a-zA-Z]+$/,
						"alertText": "* 半角英数で入力してください"
					},
					// --- CUSTOM RULES -- Those are specific to the demos, they can be removed or changed to your likings
					"ajaxUserCall": {
						"url": "ajaxValidateFieldUser",
						// you may want to pass extra data on the ajax call
						"extraData": "name=eric",
						"alertText": "* This user is already taken",
						"alertTextLoad": "* Validating, please wait"
					},
					"ajaxNameCall": {
						// remote json service location
						"url": "ajaxValidateFieldName",
						// error
						"alertText": "* This name is already taken",
						// if you provide an "alertTextOk", it will show as a green prompt when the field validates
						"alertTextOk": "* This name is available",
						// speaks by itself
						"alertTextLoad": "* Validating, please wait"
					},
					"validate2fields": {
						"alertText": "* 『HELLO』と入力してください"
					}
				};

			}
		};
		$.validationEngineLanguage.newLang();
	})(jQuery);
}

    
