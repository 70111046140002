// モジュールとなるjsファイルの読み込み
// analytics
//import {module_analytics} from './js/googleAnalytics';

// vendors
import {module_jquery_easing} from './js/jquery.easing';
import {module_slick} from './js/slick.min';
//import {module_lazyLoad} from './js/lazyload.min.js';
import {module_lazysizes} from './js/lazysizes.min.js';
//import {module_loading} from './js/loading.js';
import {module_validationEngine} from './js/jquery.validationEngine.min';
import {module_validationEngineJa} from './js/jquery.validationEngine-ja';
import {module_hashchange} from './js/jquery.ba-hashchange.min';
import {module_autoConfirm} from './js/autoConfirm';
//import {module_magnific} from './js/jquery.magnific-popup.min';
import {module_scroll} from './js/scrollAnimation';
import {module_tabs} from './js/tabs';
//import {module_gifffer} from './js/gifffer.min';

// pages
import {module_common} from './js/common';
import {module_index} from './js/index';
//import {module_leadCapture} from './js/lead-capture';
import {module_contact} from './js/contact';


// モジュール先にある関数を実行
//module_analytics();

module_jquery_easing();
module_slick();
//module_lazyLoad();
module_lazysizes();
//module_loading();
module_validationEngine();
module_validationEngineJa();
module_hashchange();
module_autoConfirm();
//module_magnific();
module_scroll();
module_tabs();
//module_gifffer();

module_common();
module_index();
//module_leadCapture();
module_contact();

// Sassファイルの読み込み
//import './sass/style.scss';